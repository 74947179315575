import React from 'react';
import { Box, Input, Divider } from '@mui/material';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';
import InputWrapper from '../InputWrapper';

interface BirthdayInputProps {
  name: string;
  label: string;
  disabled?: boolean;
}

const inputStyle: React.CSSProperties = {
  border: 'none',
  padding: 0,
  height: '44px',
  textAlign: 'center',
  fontSize: '0.875rem',
};

function BirthdayInput({ name, label, disabled }: BirthdayInputProps) {
  const formik = useFormikContext();

  const formikValueDate = (formik.values as any)[name] as string;
  const initialDate = formikValueDate
    ? new Date(new Date(formikValueDate).toISOString().replace('Z', ''))
    : undefined;

  const [month, setMonth] = React.useState<string>(
    initialDate ? format(initialDate, 'MM') : '',
  );
  const [day, setDay] = React.useState<string>(
    initialDate ? format(initialDate, 'dd') : '',
  );
  const [year, setYear] = React.useState<string>(
    initialDate ? format(initialDate, 'yyyy') : '',
  );

  const setFormikValue = async (
    newMonth: string | undefined,
    newYear: string | undefined,
    newDay: string | undefined,
  ) => {
    const date =
      newMonth && newYear?.length === 4 && newDay
        ? new Date(
            parseInt(newYear, 10),
            parseInt(newMonth, 10) - 1,
            parseInt(newDay, 10),
          ).toISOString()
        : '';
    await formik.setFieldValue(name, date, true);
    formik.setFieldTouched(name, true, true);
  };

  const onBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    type: 'month' | 'day' | 'year',
  ) => {
    if (event.target.value.length === 1 && type !== 'year') {
      const newValue = `0${event.target.value}`;
      if (type === 'month') {
        setMonth(newValue);
        setFormikValue(newValue, year, day);
      } else {
        setDay(newValue);
        setFormikValue(month, year, newValue);
      }
    }
  };

  const changeMonth = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setMonth(event.target.value);
    setFormikValue(event.target.value, year, day);
  };

  const changeYear = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setYear(event.target.value);
    setFormikValue(month, event.target.value, day);
  };

  const changeDay = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDay(event.target.value);
    setFormikValue(month, year, event.target.value);
  };

  return (
    <InputWrapper label={label} name={name}>
      <Box
        display="flex"
        flexDirection="row"
        gap={0}
        width="100%"
        height="44px"
        boxSizing="border-box"
        border={theme => `0.5px solid ${theme.palette.lightOutlineGrey.main}`}
        borderRadius="10px"
        sx={theme => ({
          '&:focus-within': {
            borderColor: 'transparent',
            outline: `1.5px solid ${theme.palette.darkGrey.main}`,
          },
          '.MuiInputBase-root::before': {
            display: 'none',
          },
          '.MuiInputBase-root::after': {
            display: 'none',
          },
        })}>
        <Input
          disabled={disabled}
          sx={{
            width: '33%',
            fontSize: '0.875rem',
          }}
          type="text"
          placeholder="MM"
          value={month}
          onChange={changeMonth}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            maxLength: 2,
            style: inputStyle,
          }}
          onBlur={event => onBlur(event, 'month')}
        />
        <Divider
          orientation="vertical"
          component="div"
          sx={theme => ({
            borderColor: theme.palette.lightOutlineGrey.main,
            borderWidth: '0.5px',
            borderLeft: 'none',
          })}
        />
        <Input
          disabled={disabled}
          sx={{
            width: '33%',
            fontSize: '0.875rem',
          }}
          type="text"
          placeholder="DD"
          value={day}
          onChange={changeDay}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            maxLength: 2,
            style: inputStyle,
          }}
          onBlur={event => onBlur(event, 'day')}
        />
        <Divider
          orientation="vertical"
          component="div"
          sx={theme => ({
            borderColor: theme.palette.lightOutlineGrey.main,
            borderWidth: '0.5px',
            borderLeft: 'none',
          })}
        />
        <Input
          disabled={disabled}
          sx={{
            width: '33%',
            fontSize: '0.875rem',
          }}
          type="text"
          placeholder="YYYY"
          value={year}
          onChange={changeYear}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            maxLength: 4,
            minLength: 4,
            style: inputStyle,
          }}
          onBlur={event => onBlur(event, 'year')}
        />
      </Box>
    </InputWrapper>
  );
}

export default BirthdayInput;
